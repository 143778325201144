import React, { useEffect, useState } from 'react';

const Location = () => {
  const [map, setMap] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const initMap = () => {
      const position = { lat: -23.8205618, lng: -45.4718078 };

      const loadGoogleMapsScript = async () => {
        try {
          const response = await fetch(`/api/maps?lat=${position.lat}&lng=${position.lng}`);
          const scriptContent = await response.text();

          const script = document.createElement('script');
          script.text = scriptContent;
          script.async = true;
          script.defer = true;
          document.head.appendChild(script);

          script.onload = () => {
            if (window.google && window.google.maps) {
              const { Map, Marker } = window.google.maps;

              const mapInstance = new Map(document.getElementById('map'), {
                zoom: 15,
                center: position,
                mapId: 'DEMO_MAP_ID',
              });

              new Marker({
                position: position,
                map: mapInstance,
                title: 'ONEscoladeSurf',
                optimized: true,
              });

              setMap(mapInstance);
            } else {
              console.error('Google Maps JavaScript API could not be loaded.');
              setError('Failed to load Google Maps');
            }
          };

          script.onerror = () => {
            console.error('Google Maps JavaScript API could not load.');
            setError('Failed to load Google Maps');
          };
        } catch (error) {
          console.error('Error fetching Google Maps script from server:', error);
          setError('Failed to load Google Maps');
        }
      };

      loadGoogleMapsScript();
    };

    initMap();
  }, []);

  return (
    <div className='mr-2 ml-2'>
      <div id="map" className='h-[400px] w-full mt-[20px]'></div>
      {error && <p>{error}</p>}
    </div>
  );
};

export default Location;
