import React from 'react'
import aboutImg from '../Assets/teco5.jpg'

const About = () => {
  return (
    <div className='text-blue-900 max-w-[1200px] mx-auto my-12 bg-blue-200 rounded-lg shadow-lg' id='about'>
      <h2 className='text-4xl font-bold text-center bm-4 primary-color'>Sobre mim e o surf</h2>
        <div className='md:grid md:grid-cols-2 sm:py-16'>
            <div className='mt-4 md:mt-0 text-left flex'>
                <div className='my-auto mx-6'>
                    <p className='text-base lg:text-lg'> 
  Meu nome é Odarci Nonato, mais conhecido como <b>Teco</b>, <u>surfista profissional há 10 anos.</u><br />
  Sou natural de São Sebastião, filho de pescador 🎣, que herdou do irmão a paixão pelo surf. Pratico o surf desde 5 anos de idade e desde então posso dizer que vivo desse esporte. Sempre fui incentivado por toda minha família, comecei com as competições ainda muito jovem em competições amadores.🏆<br />
  Mas, foi em 2019 que <b>me tornei surfista profissional</b> e fiz desse esporte minha vida.<br />
  O compromisso com o surf é o mesmo que com minha família, amigos e alunos. O surf entrou na minha vida através do apoio da minha família e isso em levo comigo sempre.
</p>
                </div>
            </div>

            <img className='mx-auto rounded-3xl py-8 md:py-0' src={aboutImg} width={300} height={300} alt='surfer on a wave'/>

        </div>
    </div>
  )
}

export default About