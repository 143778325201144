import React from 'react';
import herovid from '../Assets/hero.MP4';

const Hero = ({ nav }) => {
  console.log('Hero nav state:', nav);
  return (
    <div className='w-full h-[90vh]'>
      <video src={herovid} className='w-full h-full object-cover' autoPlay loop muted playsInline alt='Surfer catching a wave'></video>
      <div className='max-w-[1200px] m-auto'>
        <div className={`absolute top-[40%] w-full md:w-[50%] max-w-[600px] h-full flex flex-col p-4 text-white ${nav ? 'hidden' : 'block'}`}>
          <h2 className={`py-4 italic text-2xl'>Aprende a surfar na ${nav ? 'block' : 'hidden'}`}></h2>
          <h1 className='font-bold text-4xl'>ON Escola de Surf</h1>
          <p>Reserve sua aula de surf individual ou em grupo com o surfista profissional Odarci Nonato agora!</p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
