import React from 'react'
import img1 from '../Assets/img1.png'
import img2 from '../Assets/img2.png'
import img3 from '../Assets/img3.png'
import img4 from '../Assets/img4.png'
import img5 from '../Assets/img5.png'

const Galery = () => {
  return (
    <div id='gallery' className='max-w-[1200px] m-auto w-full px-4 py-16'>
        <h2 className='p-4 text-4xl text-center text-blue-900'>Galeria</h2>
        <div className='grid sm:grid-cols-5 gap-4'>
            <div className='sm:col-span-3 col-span-2 row-span-2'>
                <img src={img5} alt='' className='w-full h-full object-cover'/>
            </div>

            <div>
                <img src={img1} alt='' className='w-full h-full object-cover'/>
            </div>

            <div>
                <img src={img2} alt='' className='w-full h-full object-cover'/>
            </div>

            <div>
                <img src={img3} alt='' className='w-full h-full object-cover'/>
            </div>

            <div>
                <img src={img4} alt='' className='w-full h-full object-cover'/>
            </div>
        </div>
    </div>
  )
}

export default Galery