import React from 'react'
import iniciantes from '../Assets/iniciantes.png'
import veronica from '../Assets/veronica.png'
import group from '../Assets/group2.png'

const Activities = () => {
  return (
    <div className='max-w-[1200px] m-auto w-full md:flex mt-[-75px]'>
        <div className='relative p-4'>
            <h3 className='absolute z-10 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-2xl font-bold'>Iniciantes</h3>
            <img className='rounded-xl w-full h-full object-cover relative border-4 border-white shadow-lg' src={iniciantes} alt='Surf beginners in a group'/>
        </div>

        <div className='relative p-4'>
            <h3 className='absolute z-10 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-blue-900 text-2xl font-bold'>Intermediario e Avancado</h3>
            <img className='rounded-xl w-full h-full object-cover relative border-4 border-white shadow-lg' src={veronica} alt='Surf beginners in a group'/>
        </div>

        <div className='relative p-4'>
            <h3 className='absolute z-10 top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] text-white text-2xl font-bold'>Familias e Grupos</h3>
            <img className='rounded-xl w-full h-full object-cover relative border-4 border-white shadow-lg' src={group} alt='Surf beginners in a group'/>
        </div>

    </div>
  )
}

export default Activities
