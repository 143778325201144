import React from 'react';
import { AiFillPhone } from 'react-icons/ai';
import { MdPlace } from "react-icons/md";
import Onlogofull from '../Assets/onlogofull.png';

const Topbar = () => {
  return (
    <div className='flex justify-between items-center px-4 py-2'>
      <div className='flex items-center'>
        <img src={Onlogofull} width={150} height={100} alt="ON Escola de Surf Logo" className='mr-4'/>
      </div>
      <div className='flex'>
        <div  className='hidden md:flex items-center px-6'>
            <MdPlace size={30} className='mr-2 text-[var(--primary-dark)]'/>
            <p className='text-sm text-gray-700'>Praia do Guaeca, São Sebastião - SP, 11600-000</p>
        </div>
        <div className='hidden md:flex items-center px-6'>
            <AiFillPhone size={30} className='mr-2 text-[var(--primary-dark)]'/>
            <a className='text-sm text-gray-700' href="https://wa.me/5512997150885" target="_blank" rel="noopener noreferrer">(12) 99715-0885</a>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
