import React from 'react'

const Prices = () => {
  return (
    <div id='booking' className='flex flex-col items-center justify-center w-full min-h-screen'>
        <h2 className='py-4 text-4xl text-blue-900'>Precos e Pacotes</h2>
        <div>
        <table className="min-w-full divide-y divide-gray-200 mt-5">
      <thead className="bg-blue-100">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider"
          >
            OPCOES
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider"
          >
            PRECO
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200">
        <tr>
          <th
            scope="row"
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
          >
            1 Aula individual
          </th>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
            150 R$
          </td>
        </tr>
        <tr>
          <th
            scope="row"
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
          >
            Alugar Prancha (parafina, quilhas e lesh incluido)
          </th>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" colSpan="2">
            80 R$/hora
          </td>
        </tr>
        <tr className="bg-blue-100">
          <th
            scope="row"
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
          >
            PACOTES
          </th>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
        </tr>
        <tr>
          <th
            scope="row"
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
          >
            5 Aulas
          </th>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" colSpan="2">
            130 R$ p. Aula – 650 R$ em total
          </td>
        </tr>
        <tr>
          <th
            scope="row"
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
          >
            10 Aulas
          </th>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" colSpan="2">
            120 R$ p. Aula – 1200 R$ em total
          </td>
        </tr>
        <tr className="bg-blue-100">
          <th
            scope="row"
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
          >
            GRUPOS
          </th>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
        </tr>
        <tr>
          <th
            scope="row"
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
          >
            até 5 Pessoas
          </th>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" colSpan="2">
            130 R$ p. Pessoa – 650 R$ em total
          </td>
        </tr>
        <tr>
          <th
            scope="row"
            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
          >
            A partir de 6 Pessoas
          </th>
          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500" colSpan="2">
            120 R$ p. Pessoa
          </td>
        </tr>
      </tbody>
    </table>
        </div>

        <button className='px-4 py-2 text-lg rounded-lg bg-blue-800 text-white' ><a href='/'>Agende sua Aula agora</a></button>
    </div>
  )
}

export default Prices