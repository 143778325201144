import React, { useState, useEffect } from 'react';
import Topbar from "./Components/Topbar";
import Navbar from "./Components/Navbar";
import Hero from "./Components/Hero";
import Activities from "./Components/Activities";
import Prices from "./Components/Prices";
import About from "./Components/About";
import Galery from "./Components/Galery";
import Location from "./Components/Location";
import Footer from "./Components/Footer";

function App() {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
    console.log('Nav toggled:', !nav); // Log the new state when toggled
  };

  useEffect(() => {
    document.title = 'ON Escola de Surf';
  }, []);

  return (
    <div>
      <Topbar />
      {/* Pass the nav state and handleNav function as props */}
      <Navbar nav={nav} handleNav={handleNav} />
      <Hero nav={nav} />
      <Activities />
      <Prices />
      <About />
      <Galery />
      <Location />
      <Footer />
    </div>
  );
}

export default App;
