import React, {useState} from 'react'
import { FaBars, FaInstagram, FaWhatsapp } from "react-icons/fa";

const Navbar = ({ nav, handleNav }) => {

    console.log('Navbar nav state:', nav);
  return (
    <div className='w-full min-h-[50px] flex justify-between items-center absolute z-q0 text-white bg-blue-200/80'>
        <ul className='hidden sm:flex px-4'>
            <li>
                <a href='#booking'>Agende sua aula</a>
            </li>
            <li>
                <a href='#teco'>Odarci Nonato</a>
            </li>
            <li>
                <a href='#gallery'>Galeria</a>
            </li>
            <li>
                <a href='#contact'>Contato</a>
            </li>
        </ul>
        <div className='flex justify-between'>
        <a href='https://www.instagram.com/on_escoladesurf/' target='_blank' rel='noopener noreferrer'>
            <FaInstagram size={30} className='mx-4' />
        </a>
        <a href="https://wa.me/5512997150885" target="_blank" rel="noopener noreferrer">
            <FaWhatsapp size={30} className='mx-4'/>
        </a>
        </div>
        <div className='sm:hidden z-10'>
            <FaBars size={20} className='mr-4 cursor-pointer' onClick={handleNav}/>
        </div>
        <div onClick={handleNav} 
            className={nav? 'overflow-y-hidden md:hidden ease-in duration-300 absolute text-gray-300 left-0 top-0 h-screen w-full bg-black/90 px-4 py-7 flex-col' : 'absolute top-0 h-screen left-[-100%] ease-in duration-500'}>
            <ul className='h-full w-full text-center pt-12'>
            <li className='text-2xl py-8'>
                <a href='#booking'>Agende sua aula</a>
            </li>
            <li className='text-2xl py-8'>
                <a href='#teco'>Odarci Nonato</a>
            </li>
            <li className='text-2xl py-8'>
                <a href='#galery'>Galeria</a>
            </li>
            <li className='text-2xl py-8'>
                <a href='#contact'>Contato</a>
            </li>
            </ul>
        </div>
    </div>
  )
}

export default Navbar